import get from 'lodash/get';
import { PortableText } from 'types';

export default (portableText: PortableText | string): string => {
  if (typeof portableText === 'string') {
    return portableText;
  }

  return portableText.reduce(
    (text: string, portableTextBlock: unknown): string => {
      if (get(portableTextBlock, '_type') === 'block') {
        return (
          text +
          get(portableTextBlock, 'children', [])
            .map((child: unknown) => get(child, 'text', ''))
            .join('')
        );
      }

      return text;
    },
    ''
  );
};
