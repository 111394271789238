import Faker from 'faker';
import { Entry } from 'types';


export const Mock = {
  entry: (): Entry => {
    const entry = {
      _id: Faker.random.uuid(),
      _type: 'entry',
      title: Faker.random.words(Math.round(Math.random() * 10)),
      date: Faker.date.past(),
      tags: [
        {
          _id: Faker.random.uuid(),
          _type: 'tag',
          title: Faker.random.words(1),
        },
        {
          _id: Faker.random.uuid(),
          _type: 'tag',
          title: Faker.random.words(1),
        },
        {
          _id: Faker.random.uuid(),
          _type: 'tag',
          title: Faker.random.words(1),
        },
      ],
      publication: {
        _id: Faker.random.uuid(),
        _type: 'publication',
        title: Faker.random.words(2),
        url: '',
        bioUrl: ''
      },
      slug: Faker.internet.url(),
      content: null,
      isFeatured: false,
      image: !!Math.round(Math.random())
        ? { 
          src: Faker.random.image(),
          alt: '',
          credit: ''
        }
        : null,
    };

    return {
      ...entry,
      searchString: `${entry.title} | ${entry.publication.title} | ${entry.tags.map(tag => tag.title).join(' ')} | ${entry.slug}`.toLowerCase()
    }
  }
}

export default Mock;
