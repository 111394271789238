import React from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Animate } from 'react-move';
import { easeExpInOut } from 'd3-ease';

import { RootReducer } from 'state/reducers';
import { Tag } from 'components';
import { Entry } from 'types';

const Entries: React.FC<{ entries: Entry[] }> = ({ entries }) => {
  const searchString = useSelector((state: RootReducer) => state.ui.searchString);
  const filteredEntries = !!searchString
    ? entries.filter(entry => entry.searchString.includes(searchString.toLowerCase()))
    : entries;

  return (
    <div className="Entries relative w100 flex flex-wrap px_5 content-padding pb4">
      {filteredEntries.map((entry, i) => {
        const translateX = Math.random() * 5 * (Math.random() > .5 ? 1 : -1);
        const translateY = Math.random() * 5 * (Math.random() > .5 ? 1 : -1);
        const rotate = Math.random() * 5 * (Math.random() > .5 ? 1 : -1);

        if (!entry || !entry.date || !entry.title) return null;

        return (
          <Animate
            start={{
              opacity: 0
            }}
            enter={{
              opacity: [1],
              timing: { duration: 2000, ease: easeExpInOut, delay: 75 * i }
            }}
          >
            {({ opacity }) => (
              <a
                key={entry._id}
                style={{ opacity }}
                className="Entries__entry block text-decoration-none px_5 mb1"
                href={entry.slug}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="Entries__entry__inner h100 flex flex-col" style={{
                  transform: `
                    translate(${translateX}%, ${translateY}%)
                    rotate(${rotate}deg)
                  `
                }}>
                  <div className="Entries__entry__header flex justify-between p_5 md:p1">
                    <span className="text-sm block uppercase text-mono">{entry.publication ? entry.publication.title : 'Journal'}</span>
                    <span className="text-sm block capitalize">{format(entry.date, 'yyyy/LL/dd')}</span>
                  </div>
                  <div className="h100 p_5 md:p1 flex flex-col justify-between items-start">
                    <div className="Entries__entry__info mb3 flex flex-col justify-between">
                      <span className="text-card-title block mb1 capitalize">{entry.title}</span>
                      <span className="block mb1 uppercase">
                        {entry.tags.map(tag => <Tag key={tag._id} tag={tag.title} />)}
                      </span>
                    </div>
                    {entry.image && (
                      <div className="Entries__image wauto inline-block transition colorized">
                        <img alt="" src={entry.image.src} />
                      </div>
                    )}
                  </div>
                </div>
              </a>
            )}
          </Animate>
        );
      })}
    </div>
  )
};


export default Entries;
