import ApiClient from 'lib/ApiClient';
import { Entry } from 'types';

export type GetEntries = { type: 'GET_ENTRIES' };
export type GetEntriesPending = { type: 'GET_ENTRIES_PENDING' };
export type GetEntriesRejected = { type: 'GET_ENTRIES_REJECTED', payload: Error };
export type GetEntriesFulfilled = { type: 'GET_ENTRIES_FULFILLED', payload: Entry[] };
export const getEntries = (mockEntries: number = 0) => ({
  type: 'GET_ENTRIES',
  payload: ApiClient.fetchEntries(mockEntries)
});

export type EntriesAction = GetEntries | GetEntriesPending | GetEntriesRejected | GetEntriesFulfilled;
