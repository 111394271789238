import React from 'react';
import { animateScroll } from 'react-scroll';

import { Button } from 'components';
import { Settings } from 'types';

const Footer: React.FC<{ settings: Settings }> = ({ settings }) => (
  <div className="Footer px1_5 my2 flex justify-between items-center text-sm opacity-0 flex-col md:flex-row fade-in">
    <div className="wauto mb2 md:mb0">&copy; {new Date().getFullYear()} Ali Francis. Some rights reserved.</div>
    <div className="wauto">
      {settings.links && settings.links.length && settings.links.map(link => (
        <a className="mr1 none md:inline" href={link.url}>{link.label}</a>
      ))}
      <Button
        ariaLabel="Scroll to Top of Page"
        label="Back to Top"
        onClick={animateScroll.scrollToTop}
      />
    </div>
  </div>
);

export default Footer;
