import Sanity from 'lib/SanityClient';
import sanitizeEntries from 'state/sanitizers/sanitizeEntries';
import Mock from 'lib/Mock';
import { Entry, Settings } from 'types';

export const ApiClient = {
  fetchEntries: async (mockEntries: number = 0): Promise<Entry[] | null> => [
    ...(await Sanity.fetch(`*[_type == 'entry']
      {
        'image': {
          'src': image.asset->url,
          'caption': image.caption,
          'alt': image.alt
        },
        'tags': *[_type == 'tag' && _id in ^.tags[]._ref],
        'publication': *[_type == 'publication' && _id == ^.publication._ref][0],
        ...
      }`).then(sanitizeEntries) || []),
    ...[...(new Array(mockEntries))].map(Mock.entry)
  ],
  fetchSettings: async (): Promise<Settings> => Sanity.fetch(
    `*[_type == 'settings' && _id == '_settings'][0] {
      'image': {
        'src': image.asset->url,
        'caption': image.caption,
        'alt': image.alt
      },
      'seoImage': {
        'src': seoImage.asset->url
      },
      ...
    }`
  )
};

export default ApiClient;
