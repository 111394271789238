import { Action, Entry } from 'types';

export type EntriesReducer = {
  entries: Entry[]
}

const initialState: EntriesReducer = {
  entries: [],
};

export default (
  state: EntriesReducer = initialState,
  action: Action
): EntriesReducer => {
  switch (action.type) {
    case 'GET_ENTRIES_FULFILLED': {
      return {
        ...state,
        entries: action.payload
      }
    }
    default: {
      return state;
    }
  }
};
