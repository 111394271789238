import { Action, Settings } from 'types';

export type SettingsReducer = {
  settings: Settings | null;
}

const initialState: SettingsReducer = {
  settings: null,
};

export default (
  state: SettingsReducer = initialState,
  action: Action
): SettingsReducer => {
  switch (action.type) {
    case 'GET_SETTINGS_FULFILLED': {
      return {
        ...state,
        settings: action.payload
      }
    }
    default: {
      return state;
    }
  }
};
