import { Action } from 'types';

export type UiReducer = {
  aboutIsActive: boolean;
  aboutIsTransitioningOut: boolean;
  statusIsActive: boolean;
  searchString: string;
}

const initialState: UiReducer = {
  aboutIsTransitioningOut: false,
  aboutIsActive: false,
  statusIsActive: false,
  searchString: ''
};

export default (
  state: UiReducer = initialState,
  action: Action
): UiReducer => {
  switch (action.type) {
    case 'SET_ABOUT_ACTIVE': {
      return {
        ...state,
        aboutIsActive: action.payload
      }
    }
    case 'SET_ABOUT_IS_TRANSITIONING_OUT': {
      return {
        ...state,
        aboutIsTransitioningOut: action.payload
      }
    }
    case 'SET_STATUS_ACTIVE': {
      return {
        ...state,
        statusIsActive: action.payload
      }
    }
    case 'SET_SEARCH_STRING': {
      return {
        ...state,
        searchString: action.payload
      }
    }
    default: {
      return state;
    }
  }
};
