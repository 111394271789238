import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from '@sanity/block-content-to-react';
import { Animate } from 'react-move';
import { easeExpInOut } from 'd3-ease';

import SanityClient from 'lib/SanityClient';
import { RootReducer } from 'state/reducers';

import { Button, Img } from 'components';
import { Settings } from 'types';

const builder = imageUrlBuilder(SanityClient);

const About: React.FC<{
  bio: Settings['bio'];
  image: Settings['image'];
  links: Settings['links'];
}> = ({ bio, image, links }) => {
  const aboutIsActive = useSelector((state: RootReducer) => state.ui.aboutIsActive);
  const isTransitioningOut = useSelector((state: RootReducer) => state.ui.aboutIsTransitioningOut);
  const [imageWidth, setImageWidth] = useState<number>(0);
  const [imageHeight, setImageHeight] = useState<number>(0);
  const [imagePositionMultiplier, setImagePositionMultiplier] = useState<number>(Math.random());

  useEffect(() => {
    const imgObj = new Image();
    imgObj.src = image.src;
    imgObj.addEventListener('load', function() {
      setImageWidth(this.naturalWidth);
      setImageHeight(this.naturalHeight);
    });
  }, [image.src]);

  useEffect(() => {
    const changeImagePosition = setInterval(
      () => setImagePositionMultiplier(Math.random()),
      10000
    );
    const changeImagePositionTimeout = setTimeout(
      () => setImagePositionMultiplier(Math.random()),
      1000
    );

    return () => {
      clearInterval(changeImagePosition);
      clearTimeout(changeImagePositionTimeout);
    };
  }, []);

  if (!aboutIsActive || !imageHeight || !imageWidth) return null;

  return (
    <Animate
      start={{
        opacity: 0
      }}
      enter={{
        opacity: [1],
        timing: { duration: 2000, ease: easeExpInOut }
      }}
      leave={{
        opacity: [0],
        timing: { duration: 2000, ease: easeExpInOut }
      }}
    >
      {({ opacity }) => (
        <div
          className={cx('About content-padding fixed bg-color-primary w100 h100 t0 z-1 overflow-auto', {
            'About--transition-out transition-long': isTransitioningOut
          })}
          style={{ opacity }}
        >
          <div className="About__inner flex-col-reverse">
            <div className="About__text text-shadow relative text-lg col-12 md:col-10 z-1 p1 overflow-x-hidden">
              <BlockContent blocks={bio} />
            </div>
            <div className="About__links flex px1 mt2 mb4">
              {links && links.map(link => <Button className="mr1" to={link.url} label={link.label} ariaLabel={link.label} />)}
            </div>
            <div className="About__image-container fixed col-4 w100 flex flex-wrap">
              {[...(new Array(16))].map((_, i) => {
                const xMultiplier = (imagePositionMultiplier + Math.random()) / 1.5;
                const yMultiplier = (imagePositionMultiplier + Math.random()) / 1.5;
                const rotateMultiplier = Math.random() * 15 * (Math.round(Math.random() * -1));

                return (
                  <Animate
                    key={i}
                    start={{
                      opacity: 0
                    }}
                    enter={{
                      opacity: [1],
                      timing: { duration: 2000, ease: easeExpInOut, delay: 75 * i }
                    }}
                    leave={{
                      opacity: [0],
                      timing: { duration: 2000, ease: easeExpInOut, delay: 75 * i }
                    }}
                  >
                    {({ opacity }) => (
                      <div
                        className="About__image-clip shadow-lg col-3 colorized"
                        style={{
                          opacity,
                          transform: `
                            translate3d(
                              ${Math.floor(imageWidth / 32 * xMultiplier)}px,
                              ${Math.floor(imageHeight / 32 * yMultiplier)}px,
                              0
                            )
                            rotate(${rotateMultiplier}deg)
                          `
                        }}>
                        <Img
                          className="block w100"
                          src={builder.image(image.src).rect(
                            Math.floor((i % 4) * (imageWidth / 4)),
                            Math.floor(Math.floor(i / 4) * (imageHeight / 4)),
                            Math.floor(imageWidth / 4),
                            Math.floor(imageHeight / 4)
                          ).width(200).url() || ''}
                          alt=""
                        />
                      </div>
                    )}
                  </Animate>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </Animate>
  );
};

export default About;
