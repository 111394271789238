import ApiClient from 'lib/ApiClient';
import { Settings} from 'types';

export type GetSettings = { type: 'GET_SETTINGS' };
export type GetSettingsPending = { type: 'GET_SETTINGS_PENDING' };
export type GetSettingsRejected = { type: 'GET_SETTINGS_REJECTED', payload: Error };
export type GetSettingsFulfilled = { type: 'GET_SETTINGS_FULFILLED', payload: Settings };
export const getSettings = () => ({
  type: 'GET_SETTINGS',
  payload: ApiClient.fetchSettings()
});

export type SettingsAction = GetSettings | GetSettingsPending | GetSettingsRejected | GetSettingsFulfilled;
