import React from 'react';
import Helmet from 'react-helmet';

import { Image } from 'types';

const Meta: React.FC<{ title: string; description: string; image: Image }> = ({ title = 'Ali Francis', description = '', image }) => (
  <Helmet>
    {description ? (
      <title>{title} - {description}</title>
    ) : (
      <title>{title}</title>
    )}
    
    <meta name="description" content={description} />
    <link rel="canonical" href="https://www.alifrancis.com/"/>

    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    
    <meta property="og:url" content="https://www.alifrancis.com/" />
    <meta property="og:type" content="website" />
    <meta property="og:locale" content="en_US" />

    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:site" content="@apwfrancis" />
    <meta name="twitter:creator" content="@apwfrancis" />

    {/* Helmet can't handle fragments */}
    {!!image && !!image.src && (
      <meta property="og:image" content={`${image.src}?w=1200`} />
    )}
    {!!image && !!image.src && (
      <meta name="twitter:image" content={`${image.src}?w=1200`} />
    )}
    {!!image && !!image.src && (
      <meta name="twitter:card" content="summary_large_image" />
    )}
  </Helmet>
);

export default Meta;
