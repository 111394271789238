export type SetSearch = { type: 'SET_SEARCH_STRING'; payload: string };
export const setSearchString = (string: string) => ({
  type: 'SET_SEARCH_STRING',
  payload: string
});

export type SetAboutActive = { type: 'SET_ABOUT_ACTIVE'; payload: boolean };
export const setAboutActive = (isActive: boolean) => ({
  type: 'SET_ABOUT_ACTIVE',
  payload: isActive
});

export type SetAboutIsTransitioningOut = { type: 'SET_ABOUT_IS_TRANSITIONING_OUT'; payload: boolean };
export const setAboutIsTransitioningOut = (isTransitioningOut: boolean) => ({
  type: 'SET_ABOUT_IS_TRANSITIONING_OUT',
  payload: isTransitioningOut
});

export type SetStatusActive = { type: 'SET_STATUS_ACTIVE'; payload: boolean };
export const setStatusActive = (isActive: boolean) => ({
  type: 'SET_STATUS_ACTIVE',
  payload: isActive
});

export type UiAction = SetSearch | SetAboutActive | SetStatusActive | SetAboutIsTransitioningOut;
