import { combineReducers, } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import entries from 'state/reducers/entries';
import settings from 'state/reducers/settings';
import ui from 'state/reducers/ui';

export const createRootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  entries,
  settings,
  ui
});

export type RootReducer = ReturnType<ReturnType<typeof createRootReducer>>;
