import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compareDesc } from 'date-fns';

import { getEntries } from 'state/actions/entriesActions';
import { getSettings } from 'state/actions/settingsActions';
import { RootReducer } from 'state/reducers';

import { About, Nav, Entries, Meta, Footer } from 'components';

let theme: string = 'theme-0';
const changeTheme = function() {
  const newTheme: string = `theme-${Math.floor(Math.random() * 8) + 1}`;

  if (theme === newTheme) {
    changeTheme();
  } else {
    document.body.classList.remove(theme);
    document.body.classList.add(newTheme);
  
    theme = newTheme;
  }
}

const App: React.FC = () => {
  const dispatch = useDispatch();
  const getSettingsAction = useCallback(() => dispatch(getSettings()), [dispatch]);
  const getEntriesAction = useCallback((mockEntries) => dispatch(getEntries(mockEntries)), [dispatch]);

  const settings = useSelector((state: RootReducer) => state.settings.settings);
  const entries = useSelector((state: RootReducer) => {
    const sortedEntries = state.entries.entries
      .sort(
        (a, b) => compareDesc(a.date, b.date)
      )
      .sort(
        (a, b) => a.isFeatured === b.isFeatured
          ? 0
          : a.isFeatured && !b.isFeatured
            ? -1
            : 1
      );

    return sortedEntries;
  });

  useEffect(() => {
    getSettingsAction();
  }, [getSettingsAction]);

  useEffect(() => {
    if (settings && !entries.length) getEntriesAction(settings.mockEntries);
  }, [getEntriesAction, settings, entries]);

  if (!settings || !entries.length) return null;

  changeTheme()
  setTimeout(() => changeTheme(), 1500);

  return (
    <div className="App w100 overflow-x-hidden">
      {settings && (
        <Meta
          title={settings.seoTitle}
          description={settings.seoDescription}
          image={settings.seoImage}
        />
      )}
      <Nav />
      {entries && (
        <Entries entries={entries} />
      )}
      {settings && (
        <>
          <About image={settings.image} bio={settings.bio} links={settings.links} />
          <Footer settings={settings} />
        </>
      )}
    </div>
  );
};

export default App;
