import memoize from 'lodash/memoize';
import get from 'lodash/get';
import { parse } from 'date-fns';

import portableTextToString from 'utils/portableTextAsString';
import { Entry, Publication, Tag } from 'types';

export default memoize(function(data: unknown) {
  if (!Array.isArray(data)) {
    console.warn('Entries Sanitizer: Data received is not an array of entries.');
    return null;
  }

  return data.map(function(entry): Entry {
    const _id = get(entry, '_id', '');
    const _type = get(entry, '_type', '');
    const title = get(entry, 'title', '');
    const date = get(entry, 'date')
      ? parse(get(entry, 'date'), 'yyyy-LL-dd', new Date())
      : new Date();
    const publication: Publication = get(entry, 'publication');
    const tags: Tag[] = get(entry, 'tags');
    const slug = get(entry, 'url', '');
    const isFeatured = get(entry, 'isFeatured', false);
    const image = get(entry, 'image.src')
      ? {
          ...get(entry, 'image'),
          src: `${get(entry, 'image.src')}?w=400`
        }
      : null;
    const content = get(entry, 'content', null);
    const searchString = `${title} | ${publication?.title} | ${tags.map(tag => tag?.title).join(' ')} | ${slug} | ${portableTextToString(content || "")}`.toLowerCase();

    return {
      _id,
      _type,
      title,
      date,
      publication,
      tags,
      slug,
      image,
      isFeatured,
      content,
      searchString
    };
  })
});
