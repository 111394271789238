import React, { useCallback, ReactText } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import BlockContent from '@sanity/block-content-to-react';

import { setAboutActive, setAboutIsTransitioningOut, setStatusActive, setSearchString } from 'state/actions/uiActions';
import { RootReducer } from 'state/reducers';

import { Button, TextField } from 'components';

const Nav: React.FC = () => {
  const dispatch = useDispatch();
  const openAbout = useCallback(() => dispatch(setAboutActive(true)), [dispatch]);
  const closeAbout = useCallback(
    () => {
      dispatch(setAboutIsTransitioningOut(true));
      setTimeout(() => {
        dispatch(setAboutIsTransitioningOut(false));
        dispatch(setAboutActive(false));
      }, 500);
    },
    [dispatch]
  );

  const aboutIsOpen = useSelector((state: RootReducer) => state.ui.aboutIsActive);
  const aboutIsTransitioningOut = useSelector((state: RootReducer) => state.ui.aboutIsTransitioningOut);
  const statusLabel = useSelector((state: RootReducer) => state.settings.settings?.statusLabel);
  const statusMessage = useSelector((state: RootReducer) => state.settings.settings?.statusMessage);

  const searchString = useSelector((state: RootReducer) => state.ui.searchString);
  const setNavSearchString = (string: ReactText) => dispatch(setSearchString(string as string));

  const statusIsOpen = useSelector((state: RootReducer) => state.ui.statusIsActive);
  const toggleStatus = useCallback(() => dispatch(setStatusActive(!statusIsOpen)), [dispatch, statusIsOpen]);

  return (
    <nav
      className={cx('Nav transition-long fixed w100 flex items-center mb1 z-2', {
        'Nav--about-active': aboutIsOpen
      })}
    >
      <div className={cx('Nav__inner relative w100 p1 flex items-center justify-between')}>
        <div className="Nav__title">
          <span className="Nav__title__text color-text text-shadow">Ali Francis</span>
        </div>
        <div className="Nav__search w100 mx1 none md:block">
          <TextField
            variant="primary"
            name="search"
            autoComplete={false}
            className="w100"
            ariaLabel="Search Ali's Work"
            placeholder="Search Ali's Work"
            value={searchString}
            onChange={setNavSearchString}
          />
        </div>
        <div className="Nav__actions flex">
          {!aboutIsOpen || aboutIsTransitioningOut ? (
            <Button
              className={cx({ 'mr_5 md:mr1': !!statusLabel })}
              label="About"
              ariaLabel="About"
              onClick={openAbout}
            />
          ) : (
            <Button
              className={cx({ 'mr_5 md:mr1': !!statusLabel })}
              label="Close"
              ariaLabel="Close About"
              onClick={closeAbout}
            />
          )}
          <Button
            variant={!!statusLabel && statusLabel.length > 3 ? 'primary' : 'round'}
            label={statusLabel}
            ariaLabel={'status'}
            onClick={toggleStatus}
          />
          <div
            className={
              cx(
                'Nav__status bg-color-white absolute transition col-11 md:col-4 p1 radius-xs',
                { 'Nav__status--active': statusIsOpen }
              )
            }
          >
            <BlockContent blocks={statusMessage} />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
